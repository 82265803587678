@use '@angular/material' as mat;
@import 'styles';
@import 'colors';
@import 'variables';

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($primary-light),
      accent: mat.m2-define-palette($accent),
      warn: mat.m2-define-palette($warn),
    ),
    typography: $custom-typography,
  )
);

$primary-color: mat.m2-get-color-from-palette(map-get($light-theme, primary), 700);

.text-primary {
  color: $primary-color;
}

// todo: need to update the name of class later (adjust black & white text)
.adjust-bw-color {
  color: black;
}

@include custom-theme($light-theme);
