.flex {
  display: flex;
}

.justify-text {
  text-align: justify;
}

.full-width {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.p-15 {
  padding: 15px !important;
}

.ms-5 {
  margin-inline-start: 5px;
}

.ms-10 {
  margin-inline-start: 10px;
}

.me-5 {
  margin-inline-end: 5px !important;
}

.me-10 {
  margin-inline-end: 10px !important;
}

.me-15 {
  margin-inline-end: 15px !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-25 {
  margin-left: 25px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-160px {
  width: 160px;
}

.w-200px {
  width: 200px;
}

.success-color {
  color: #34a853;
}

.error-color {
  color: #ea4335;
}

.move-right {
  float: right;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.italic-info-text {
  font-style: italic;
  color: dodgerblue;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.gap-10 {
  gap: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.h-10 {
  height: 10px !important;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px !important;
}

.text-center {
  text-align: center;
}

.bottom-red-line {
  border-bottom: 3px solid red;
  width: 50px;
  display: block;
}

.w-100 {
  width: 100% !important;
}

.w-16 {
  width: 16px !important;
}

.p-0 {
  padding: 0 !important;;
}

.text-decoration-none {
  text-decoration: none;
}

.ls-1 {
  letter-spacing: 1px;
}

.cursor-pointer{
  cursor: pointer !important;
}
