@import "../variables";

// TO ALIGN THE ICON AND TEXT IN SIDEBAR
.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

* {
  --mdc-icon-button-icon-size: unset !important; // icons and ripple alignment issue (top bar)
  --mdc-list-list-item-label-text-font: $body-font-family; // font family of side bar text
  --mat-menu-item-label-text-font: $body-font-family; // menus text (i.e. top bar profile menu)
}
