@use '@angular/material' as mat;
@import '@angular/material';

$gapFromSideNavBigScreens: 25px;
$gapFromSideNavMobileScreens: 10px;

// Typography
$body-font-family: 'Changa', sans-serif;
$body-font-weight: 400;

$header-font-family: 'Montserrat', Helvetica, Arial, sans-serif;
$header-font-weight: 700;

$custom-typography: mat.m2-define-typography-config(
  $font-family: $body-font-family,

  $headline-1: mat.m2-define-typography-level(112px, 112px, 400, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, $header-font-weight, $header-font-family),
  $headline-6: mat.m2-define-typography-level(20px, 32px, $header-font-weight, $header-font-family),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400, $header-font-family),
  $body-1: mat.m2-define-typography-level(15px, 24px, 400, $header-font-family),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 400),
);
