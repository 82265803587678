@use '@angular/material' as mat;
@import '@angular/material';
@import 'colors';
@import 'variables';
@import 'styles/material-override';
@import 'common';


@include mat.all-component-typographies($custom-typography);
@include mat.core();

@mixin custom-theme($theme) {
  @include mat.core-theme($theme);
  @include mat.all-component-themes($theme);

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $divider-border: solid 1px mat.m2-get-color-from-palette($foreground, divider);

  html,
  body {
    height: 100vh;
    margin: 0;
    background: mat.m2-get-color-from-palette($background,  'background')
  }

  *:not(.mat-icon) {
    font-family: $body-font-family;
    font-weight: $body-font-weight;
  }

  .mat-mdc-simple-snack-bar span {
    margin: auto;
    text-align: center;
  }

  @keyframes spinner-inside-button {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-inside-button:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner-inside-button .8s linear infinite;
  }

  .ns-container {
    margin-inline-start: $gapFromSideNavBigScreens;
    margin-inline-end: $gapFromSideNavBigScreens;

    @media (max-width: 600px) {
      margin-inline-start: $gapFromSideNavMobileScreens;
      margin-inline-end: $gapFromSideNavMobileScreens;
    }
  }



  // ************************************************************************************************************************
  // *** OVERRIDING THE STYLES OF OUR MAT-FORM-FIELDS TO MAKE IT LOOKS LIKE LEGACY INPUTS THAT WE HAD IN ANGULAR MATERIAL 14
  // ************************************************************************************************************************
  .mdc-text-field--filled,
  .mat-mdc-text-field-wrapper:hover,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mdc-text-field {
    padding: 0 !important;
  }

  //.mdc-text-field {
  //  height: 50px !important;
  //}

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  //.mdc-icon-button {
  //  font-size: 35px !important;
  //}

  .mat-mdc-paginator-container .mat-mdc-form-field {
    height: 15px !important;
    .mat-mdc-form-field-infix {
      padding: 8px !important;
    }

    .mat-mdc-paginator-range-actions {
      margin-top: 8px !important;
    }
  }

  .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
    margin-top: 8px !important;
  }
}
